@font-face {
    font-family: blueprint;
    src: url("../static/blueprint.ttf");
}

@font-face {
    font-family: cocogoose-regular;
    src: url("../static/Cocogoose\ Pro\ Regular\ Trial.ttf");
}

@font-face {
    font-family: typograph-semibold;
    src: url("../static/TYPOGRAPH\ PRO\ Semi\ Bold.ttf");
}

@font-face {
    font-family: typograph-semibold-italic;
    src: url("../static/TYPOGRAPH\ PRO\ Semi\ Bold\ Italic.ttf");
}

@font-face {
    font-family: typograph-extrabold;
    src: url("../static/TYPOGRAPH\ PRO\ Extra\ Bold.ttf");
}

@font-face {
    font-family: typograph-light;
    src: url("../static/TYPOGRAPH\ PRO\ Light.ttf");
}

@font-face {
    font-family: typograph-light-italic;
    src: url("../static/TYPOGRAPH\ PRO\ Light\ Italic.ttf");
}

@font-face {
    font-family: typograph-ultralight;
    src: url("../static/TYPOGRAPH\ PRO\ Ultra\ Light.ttf");
}

@font-face {
    font-family: typograph-ultralight-italic;
    src: url("../static/TYPOGRAPH\ PRO\ Ultra\ Light\ Italic.ttf");
}

@font-face {
    font-family: quando;
    src: url("../static/Quando-Regular.ttf");
}

@font-face {
    font-family: quando-bold;
    font-weight: 700px;
    src: url("../static/Quando-Regular.ttf");
}

@font-face {
    font-family: quando-light;
    font-weight: 200px;
    src: url("../static/Quando-Regular.ttf");
}

@font-face {
    font-family: quando-ultralight;
    font-weight: 100px;
    src: url("../static/Quando-Regular.ttf");
}

@font-face {
    font-family: tourmaline-lines;
    font-weight: 100px;
    src: url("../static/Tourmaline-Lines.ttf");
}



/* Icons */



/* .icon-skill-med {
    width: 34px;
    height: 34px;
    border-radius: 1000px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-skill-small {
    width: 28px;
    height: 28px;
    border-radius: 1000px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
} */

.icon-skill-python {
    background-image: url("../static/python.webp");
}

.icon-skill-react {
    background-image: url("../static/react.png");
}

.icon-skill-pytorch {
    background-image: url("../static/pytorch.png");

}

.icon-skill-tensorflow {
    background-image: url("../static/tensorflow.png");
}

.icon-skill-scikitlearn {

}

.icon-skill-pandas {
    background-image: url("../static/pandas.png");
}

.icon-skill-photoshop {
    background-image: url("../static/photoshop.png");
}

.icon-skill-premiere {

}

.icon-skill-java {

}

.icon-skill-angular {

}

.icon-skill-js {

}

.icon-skill-css {

}

.icon-skill-swift {

}

.icon-skill-selenium {

}

.icon-skill-jupyter {

}

.icon-skill-svg {

}

.icon-skill-rstudio {

}

.icon-skill-excel {
    background-image: url("../static/excel.png");
}

.icon-skill-mysql {
    background-image: url("../static/mysql.png");
}