
:root {
    --header-color: #ffffff;
}

.headerContainer {
    height: 1150px;
}

.header {
    height: 650px;
    /* position: relative; */
    z-index: 10;
}

.canvasContainer {

    width: 100%;
    height: 100%;
    position: relative;
}

#threeCanvas {
    width: 100%;
    height: 100%;
}

.navbar {
    position: fixed;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    z-index: 5;

    color: white;
    border-color: white;
    background-color: rgba(255, 255, 255, 0.0);
    transition-property: background-color mix-blend-mode color;
    transition-duration: 0.5s;

}

.scrolledDown {
    color: black;
    background-color: rgba(255, 255, 255, 1);
    mix-blend-mode: normal;
    border-color: black;
    transition-property: background-color mix-blend-mode color border-color;
    transition-duration: 0.5s;
    
}

.mainheader{
    position: absolute;
    z-index: 3;
}


.navElement {
    display: block;
    color: var(--header-color);
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    /* width: 120px; */
    flex-basis: 150px;
    max-width: 150px;
    flex-grow: 1;
    
    height: 60px;
    line-height: 60px;
    
    
    border-top: 1px solid var(--header-color);
    border-bottom: 1px solid var(--header-color);
    border-right: 1px solid var(--header-color);
    font-family: typograph-light;

    transition-property: color;
}



.navbar a:first-child  { 
    border-left: 1px solid var(--header-color);
}

.navHeader {
    /* width: 320px; */
    font-family: tourmaline-lines;
    font-size: 40px;
    text-align: left;
    padding-left: 30px;
    width: 400px;
    min-width: 400px;
}

.navEnd {
    max-width: none;
}

.mainTitle {
    width: 800px;
    height: 185px;
    margin: auto;
    text-align: center;
    font-size: 8em;
    transform: translateY(-0.7em) rotateX(-90deg);
    color:rgb(255, 255, 255);
    mix-blend-mode: difference;
    z-index: 10;
    filter: saturate(100%);

    font-family: cocogoose-regular;
    position: relative;
    opacity: 0;

}

.mainTitle-animate {
    transform: translateY(-0.7em) rotateX(0deg);
    transform-origin: center;
    opacity: 1;
    transition: transform 2s, opacity 0.7s;
}

.subtitleContainer {
    width: 100%;
    background: linear-gradient(#ffffff, #eaf0fd);
    transform: translateY(-4em);
}

.mainSubtitle {
    width: 0%;
    margin: auto;
    height: 64px;
    font-size: 4em;
    text-align: center;
    font-family: typograph-light;
    position: relative;
    padding-bottom: 300px;
    overflow: hidden;
    white-space: nowrap;

    opacity: 0;
}

@keyframes blink-caret-subtitle {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }


.mainSubtitle-animate {
    width: 100%;
    opacity: 1;

    transition: 
        width 3.5s steps(40, end) 0.5s, 
        opacity 5s;
    animation:
        blink-caret-subtitle .75s step-end infinite;
    /* max-width: 100%;  */
}

