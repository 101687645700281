/*
  Hover and click on the card for animations!
*/

@import url(https://fonts.googleapis.com/css?family=Cormorant+Garamond);


.button {
    display: block;
    position: relative;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    text-shadow: inherit;
    cursor: pointer;
    outline: inherit;
    z-index: 10;

    font-family: "Cormorant Garamond", serif;
    text-transform: uppercase;
    box-sizing: inherit;
    font-size: 10.5px;
    letter-spacing: 1px;
}

.card .clearfix::after {
    content: "";
    clear: both;
    display: table;
}


.card .big {
    font-size: 150%;
}
  
.card .med {
    font-size: 100%;
    letter-spacing: .5px;
}

.card .small {
    font-size: 65%;
    letter-spacing: .5px;
}

.card .no-space {
    letter-spacing: 0px;
}


.card {
    background-color: #f4f1eb;
    background-image: url("https://www.transparenttextures.com/patterns/paper-fibers.png");
    height: 200px;
    width: 350px;
    margin: 15vh auto 0 auto;
    padding: 20px 10px 10px 10px;
    transform: rotateX(60deg) rotateY(0deg) rotateZ(45deg) scaleX(1.5) scaleY(1.5);
    /*transform-origin: 50% 100%;*/
    box-shadow: 0;
    transition: transform .4s ease,
                box-shadow .4s ease;
}

.card-animate {
    cursor: pointer;
    transform: rotateX(60deg) rotateY(0deg) rotateZ(45deg) translateZ(10px) scaleX(2) scaleY(2);
    box-shadow: 20px 20px 20px rgba(0, 0, 0, .4);
}

.card:hover {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) translateZ(10px) scaleX(2) scaleY(2);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, .4);
}

.card:hover::before {
    transform: skewX(0deg) translateX(0px) translateY(0px);
    height: 0px;
}

.card:hover::after {
    transform: skewY(0deg) translateX(0px) translateY(0px);
    width: 0px;
}

.card .left {
    float: left;
}
  
.card .right {
    float: right;
}

.card::before,
.card::after {
    content: "";
    position: absolute;
    display: block;
    background-color: grey;
    transition: transform .4s ease,
                height .4s ease,
                width .4s ease;
}

.card::before {
    width: 100%;
    height: 6px;
    bottom: 0;
    left: 0;
    transform: skewX(45deg) translateX(-3px) translateY(6px);
}

.card::after {
    height: 100%;
    width: 6px;
    top: 0;
    right: 0;
    transform: skewY(45deg) translateX(6px) translateY(-3px);
}

.socials {
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    margin: auto;
}

.socialItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.socialItemLogo {
    width: 40px;
    height: 40px;
    margin-top: 5px;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    filter: invert() brightness(25%);

}


.social-github {
    background-image: url("../static/github.png");
}


.social-email {
    background-image: url("../static/email.png");
    background-size: 175% auto;
}


.social-linkedin {
    background-image: url("../static/linkedin.png");
}