.app {
    width: 100%;
}

body {
    background-color: #ffffff;
}

html {
    scroll-behavior: smooth;
}