.project {
    width: 80%;
    height: auto;
    max-height: 350px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 3px 3px 20px 10px rgba(0, 0, 0, 0.1);
    margin: 60px;

    display: flex;
    justify-content: stretch;
    align-items: center;



    opacity: 0;
    transform: translateY(-40px);

    transition: max-height 0.1s ease-in-out;

    

}

.project-animate {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 0.6s, transform 0.4s;
}

.project-visible {
    max-height: 10000px;

    transition: max-height 0.1s ease-in-out;
}

.projectContainer .left {
    align-self: flex-start;
    flex-direction: row;
}

.projectContainer .right {
    align-self: flex-end;
    flex-direction: row-reverse;
}

.projectImageWindowOutline {
    width: 450px;
    height: 450px;
    border-radius: 1000px;
    border: 1px solid #00083c;
    
    box-shadow: 3px 3px 20px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.projectImageWindowSqueeze {
    width: 300px;
    /* height: 100%; */
}

.projectImageWindow {
    width: 420px;
    height: 420px;
    border-radius: 1000px;
    background-color: #00083c;
    flex-shrink: 1;
    /* flex-basis: 0; */

    display: flex;
    align-items: center;
    justify-content: center;
    
}

.projectImage {
    width: 90%;
    height: 90%;
    border-radius: 10000px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.project .lefty {
    transform: translateX(-42%);
}

.project .righty {
    transform: translateX(10%);
}

.projectContentContainer {
    align-self: flex-start;
    max-height: 340px;
    flex-grow: 1;

    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
}

.projectContentContainer-animate {
    height: 100%;
    max-height: 100%;
}

.projectContent {
    
    max-height: 100%;
    margin: 10px;
    box-sizing: border-box;
    padding: 20px;
    padding-bottom: 0px;
    /* background: red; */
    mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    width: 480px;
    overflow: hidden;

    transition: mask-image 0.5s;
    /* transition-delay: 1s; */
}

.projectContent-animate {
    mask-image: linear-gradient(to bottom, black 100%, transparent 100%);

    transition: mask-image 0.5s;
    /* transition-delay: 1s; */
}

.projectContent .title{
    font-size: 24px;
    font-family: typograph-semibold;
    text-decoration: none;
}

.projectContent .subtitleWrapper {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}

.projectContent .subtitle {
    font-size: 18px;
    font-family: typograph-light;

}

.projectContent .dates {
    font-size: 14px;
    font-family: typograph-light-italic;
    color: #00083c;

}

.projectContent .description {
    margin-top: 12px;
    font-size: 14px;
    font-family: quando;
}

.showMore {
    color: rgb(180, 180, 180);
    font-family: typograph-semibold;
    font-size: 16px;
    
    border-radius: 100px;
    box-sizing: border-box;
    padding: 15px;
    margin-top: 0px;
    margin-bottom: 10px;

    background-color: rgba(255, 255, 255, 0);
    box-shadow: 0px;
}

.showMore:hover {
    box-shadow: inset 1px 1px 3px 3px rgba(0, 0, 0, 0.1);

    transition: box-shadow 0.2s;
}
