

.resumeItem {
    width: 100%;
    height: 320px;
    margin: auto;
    background-color: white;
    box-shadow: 3px 3px 20px 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-flow: row;

    margin-bottom: 40px;
    opacity: 0;
    transform: translateY(-40px);

}

.resumeItem-animate {
    opacity: 1;
    transform: translateY(0px);

    transition: opacity 0.6s, transform 0.4s;
}

.resumeItem .companyLogo {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.1);
    min-width: 240px;
    height: 180px;
    flex-grow: 0;
    margin-right: 40px;
    border-radius: 20px;
}


.resumeItem .resumeItemInfo {
    flex-grow: 1;
    margin-top: 10px;
}

.resumeItem .company {
    font-size: 24px;
    color: #00083c;
    font-family: typograph-semibold;
    text-decoration: none;
}

.resumeItem .positionWrapper {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.resumeItem .position {
    font-size: 20px;
    font-family: typograph-light;
}

.resumeItem .dates {
    font-size: 14px;
    font-family: typograph-light-italic;
    color: #00083c;
}

.resumeItem .location {
    font-size: 14px;
    font-family: typograph-light-italic;
}

.resumeItem .divider {
    border: none;
    border-top: 1px dashed rgba(0, 0, 0, 0.3);
    width: 100%;
}

.resumeItem .summary {
    margin-top: 12px;
    font-size: 14px;
    font-family: quando;
}

.resumeItem .descriptionSubtitle {
    margin-top: 12px;
    font-size: 14px;
    font-family: quando;
}

.resumeItem .description {
    margin-top: 6px;
    font-size: 14px;
    font-family: quando;
}