/* General section styles */
.section {
    position: relative;
    width: 100%;
    height: auto;
    background: #ffffff;
    top: -15px;
    padding-bottom: 200px;
    /* text-align: center; */
}

.sectionContent {
    padding-bottom: 50px;
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.sectionTitle {
    width: 800px;
    margin: auto;
    text-align: center;
    font-family: typograph-extrabold;
    font-size: 40px;
    color: #00083c;

    opacity: 0.0;
    transform: translateY(30px);

}

.sectionTitle-animate {
    opacity: 1.0;
    transform: translateY(0px);

    transition: opacity 3s, transform 0.3s;
}

.sectionSubtitleContainer {
    width: 100%;
    text-align: center;
}

.sectionSubtitle-animate {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid orange; /* The typwriter cursor */
    white-space: nowrap; /*Keeps the content on a single line */
    margin: auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 3.5s steps(40, end),
      blink-caret .75s step-end infinite;
}
  
  /* The typing effect */
  @keyframes typing {
    from { max-width: 0 }
    to { max-width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }

.sectionSubtitle {
    /* width: auto; */
    font-size: 24px; 
    font-family: typograph-semibold-italic;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    color: #53587a;
    display: inline-block;
    width: auto;
    max-width: 100%;
}

/* About Me section styles */
.aboutMeImageOutline {
    background: linear-gradient(#bac0ff, #fb8686);
    width: 140px;
    height: 140px;
    border-radius: 1000px;
    margin: 40px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; 

    box-shadow: 3px 3px 20px 10px rgba(0, 0, 0, 0.1);
    z-index: 2;
    position: relative;

    opacity: 0;
    
}

.aboutMeImageOutline-animate {
    width: 340px;
    height: 340px;

    opacity: 1;

    transition: transform 0.5s, opacity 0.5s, width 0.5s, height 0.5s;
}

.aboutMeImage {

    margin: auto;
    border-radius: 1000px;
    background-image: url("../static/profile.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    width: 0px;
    height: 0px;

    
}

.aboutMeImage-animate {
    width: 300px;
    height: 300px;

    transition: width 0.5s, height 0.5s; 
}

.aboutMeTag {
    width: 260px;
    height: 100px;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 3px 3px 20px 10px rgba(0, 0, 0, 0.1);
    top: -130px;
    left: -120px;
    position: relative;
    margin: auto;
    box-sizing: border-box;
    padding: 15px;
    z-index: 3;

    opacity: 0;
    transform: translateY(-20px);

}

.aboutMeTag-animate {
    opacity: 1;
    transform: translateY(0px);

    transition: opacity 0.6s, transform 0.6s;
    transition-delay: 0.6s;
}

.nameTag {
    font-family: quando-bold;
    font-weight: 900;
    font-size: 20px;
    padding-top: 5px;
    width: 100%;
}
.ageTag {
    font-family: typograph-light;
    font-weight: 200;
    font-size: 16px;
    padding-top: 5px;
    width: 100%;
}
.locationTag {
    font-family: typograph-light;
    font-size: 14px;
    width: 100%;
    text-align: right;
}

.aboutMeDescription {
    background-color: white;
    width: 75%;
    border-radius: 20px;
    box-shadow: 3px 3px 20px 10px rgba(0, 0, 0, 0.1);
    margin: auto;
    height: auto;
    box-sizing: border-box;
    padding: 20px;
    margin-bottom: 10px;

    font-size: 24px;
    font-weight: 300;
    font-family: typograph-light;
    position: relative;
    top: -100px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    opacity: 0.0;
}

.aboutMeDescription-animate {
    opacity: 1.0;
    transition: opacity 1s linear 0.5s;
}

.aboutMeDescription::before {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	bottom: 100%;
	left: 1.5em;
	border: .75rem solid transparent;
	border-top: none;

	border-bottom-color: #fff;
	filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
}

.welcomeMessage {
    align-self: flex-start;
    margin-bottom: 20px;
}


.descriptionSection {
    width: 80%;
}


.descriptionList {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 40px;
}

.descriptionSectionTitle {
    margin-top: 30px;
    margin-bottom: 30px;
}


.descriptionListItem {
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 2px 5px 6px 3px rgba(0, 0, 0, 0.1);
    /* background-color:rgba(100, 100, 100, 0.1); */
    box-sizing: border-box;
    padding: 10px;
}

.descriptionListItem .itemTitle {
    font-family: typograph-semibold;
    font-size: 16px;
    margin-bottom: 15px;
    text-align: center;
}

.descriptionListItem .itemIcon {
    width: 160px;
    height: 160px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-radius: 10px;
}

.dogs {
    background-image: url("../static/dog.svg");
    background-color: #ffe1c1;
}

.coffee {
    background-image: url("../static/coffee.svg");
    background-color: #4c9c4c;
}

.building {
    background-image: url("../static/ideas.svg");
    background-color: #e0b5b5;
}

.machinePerception {
    background-image: url("../static/machinePerception.svg");
    background-color: #f4defa;
}

.computerVision {
    background-image: url("../static/computervision.svg");
    background-color: #e2e2e2;
}

.autonomousDriving {
    background-image: url("../static/autonomousDriving.svg");
    background-color: #d6e5ff;
}





.infiniteScrollContainerOutlineLeft {

    content: "";
    width: 40px;
    height: 40px;
    bottom: -10px;
    align-self: flex-end;
    margin-right: 60px;
    margin-bottom: 20px;
    margin-top: -80px;

    border-bottom: 15px solid #00083c;
    border-right: 15px solid #00083c;
}


.infiniteScrollContainerOutlineRight {

    content: "";
    width: 40px;
    height: 40px;
    bottom: -10px;
    align-self: flex-start;
    margin-left: 60px;
    margin-bottom: 20px;

    border-top: 15px solid #00083c;
    border-left: 15px solid #00083c;
}

.infiniteScrollTitle {
    transform: translateX(-30px);
    margin-bottom: 15px;
    font-size: 32px;
    font-family: typograph-ultralight;
    font-weight: 500;
}

.nAppear {
    opacity: 0.0;
    transition: opacity 0.2s;
}

.nAppear-animate {
    opacity: 1.0;
    transition: opacity 0.2s;
}


.infiniteScroll {
    perspective: 900px;
    width: 400px;
    height: 200px;
    justify-self: center;
    align-self: center;
}

.cube {
    width: 100%;
    height: 60px;
    transform-style: preserve-3d;
    transition: 0.1s;
    position: relative;
    display: flex;
    justify-content: center;
}

.face {
    position: absolute;
    width: 350px;
    height: 350px;
    /* opacity: 0.3; */
    /* color: #00083c; */
    color:rgba(0, 0, 0, 0.4);
    opacity: 0.6;
    /* transform-origin: center; */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    box-sizing: border-box;
    padding-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 30px;
    font-family: typograph-extrabold;
}

.face-front  { 
    transform: rotateY(  0deg) translateZ(30px);
    background: white;
    height: 60px;
}
.face-right  { 
    transform: rotateY( 90deg) translateZ(175px);
    background: rgba(0, 0, 0, 0.4);
    width: 60px;
    height: 60px;
}
.face-back   { 
    transform: rotateY(180deg) translateZ(30px) rotateX(-180deg)rotateY(-180deg); 
    background: white;
    height: 60px;
}
.face-left   { 
    transform: rotateY(-90deg) translateZ(175px);
    background:  rgba(0, 0, 0, 0.4);
    width: 60px;
    height: 60px;
}
.face-top    { 
    transform: rotateX( 90deg) translateZ(30px);
    background: white;
    height: 60px;
}
.face-bottom { 
    transform: rotateX(-90deg) translateZ(30px); ; 
    background: white;
    height: 60px;
}

/* .show-front  { transform: translateZ(-100px) rotateX(   0deg); }
.show-top  { transform: translateZ(-100px) rotateX( -90deg); }
.show-back   { transform: translateZ(-100px) rotateX(-180deg); }
.show-right   { transform: translateZ(-100px) rotateX(  -270deg); } */

.cube-animated {
    transform: translateZ(-100px) rotateX(0deg);
}











.skillContainer {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(150%);
}

.skill-large {
    background-color: white;
    height: 86px;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    min-width: 86px;

    transform: translateX(0px) translateY(0px);
    opacity: 0;

    box-shadow: 3px 3px 20px 10px rgba(0, 0, 0, 0.1);

    transition: justify-content 0.1s, transform 0.3s, opacity 0.1s;
    transition-delay: 0.4s;
    z-index: -1;
    
}

.icon-skill-large {

    width: 64px;
    height: 64px;
    padding: 0px 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

}

.text-skill-large {
    padding-right: 0px;
    font-size: 16px;
    font-family: typograph-semibold;
    width: 0px;
    opacity: 0;

    transition: width 0.1s, padding 0.1s, opacity 0.1s;
}

.text-skill-large-animated {
    padding-right: 20px;
    font-size: 16px;
    font-family: typograph-semibold;
    width: auto;
    opacity: 1;


    transition: width 0.3s, padding 0.3s, opacity 0.1s;
}

.skill-large-animated {
    justify-content: space-evenly;
    opacity: 1;
    transform: translateX(0px) translateY(0px);
    transition: justify-content 0.1s, transform 0.3s, opacity 0.2s ease-in 0.1s;
}



/* Experience section styles */
.resumeItemContainer {
    position: relative;
    width: 100%;
    height: auto;
}

/* Research and Projects section styles */
.projectContainer {
    margin-top: 60px;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;

}

/* Education section styles */
.educationContainer {
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 80px;
}