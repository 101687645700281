



.education {
    width: 270px;
    height: 360px;
    position: relative;
    margin: 20px;
}


.educationBook {
    width: 100%;
    height: 100%;
    background: rgb(101, 101, 184);
    position: absolute;
    border-radius: 5px;
    z-index: 2;
}

.educationBookPage {
    width: 99%;
    height: 100%;
    background: rgb(232, 232, 232);
    box-shadow: 1px 1px 3px rgba(89, 89, 89, 0.5);
    position: absolute;
    z-index: 1;
    
}
.frontcover {
    z-index: 1;
    transform: perspective(2000px) rotateY(0deg) skewY(0deg);
    transition-duration: 0.5s;
    transform-origin: center left;
    box-shadow: 5px 5px 10px rgba(89, 89, 89, 0.7);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.schoolMedallion {
    width: 100px;
    height: 100px;
    background-color: white;
    border-radius: 1000px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.schoolLogo {
    width: 90%;
    height: 90%;
    border-radius: 1000px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

}

.schoolName {
    width: 85%;
    text-align: center;
    font-family: quando;
    font-size: 20px;
    font-weight: 600px;
    color: white;
}

.major {
    width: 85%;
    height: 60px;
    text-align: center;
    font-family: quando;
    font-size: 20px;
    font-weight: 900px;
    color: white;
}



/* .education:hover .opened { */
.education:hover .frontcover {

    transform-origin: center left;
    transform: perspective(2000px) rotateY(-180deg) skewY(5deg);
    transition-duration: 1s;
    transition-property: all;
    z-index: 1;
}


.backcover {
    z-index: 1;
    top: -21px;
    left: 21px;
    box-shadow: 5px 5px 10px rgba(89, 89, 89, 0.3);
}


.educationBookSpine {
    content: "";
    position: absolute;
    width: 24px;
    height: 100%;
    top: -18px;
    background: rgb(101, 101, 184);
    transform: skewY(-45deg) translateY(5px) translateX(-3px);
    border-radius: 5px 6px 5px 10px;
    z-index: 1;
    filter: brightness(0.5);
} 

.frontcoverContent {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 6px;
    border: 1px dashed;
    box-sizing: border-box;
    padding: 10px;

    opacity: 1.0;
    transition: opacity 0.6s;
}

.education:hover .frontcoverContent {
    opacity: 0.2;
    transition: opacity 0.3s;
}

.educationBookPageContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.educationPageHeader {
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    margin-top: 20px;
    height: 70px;
}

.schoolMedallionSmall {
    width: 68px;
    height: 68px;
    border-radius: 1000px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;  
}

.schoolLogoSmall {
    width: 60px;
    height: 60px;
    border-radius: 1000px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

}

.educationPageHeaderText {
    margin-left: 15px;
    margin-top: 10px;
    width: 180px;
}

.schoolNameSmall {
    font-family: quando;
    font-size: 12px;
    
}

.schoolDates {
    font-family: quando;
    font-size: 10px;
    opacity: 0.5;
}

.bookPageDivider {
    margin-top: 10px;
    border: 1px dotted rgba(85, 85, 85, 0.3);
    width: 95%;
}

.educationPageStats{
    margin-left: 10px;
}

.majorSmall {
    font-family: quando;
    font-size: 14px;
}

.schoolLocation {
    font-family: quando;
    font-size: 10px;
    opacity: 0.5;

}

.minorSmall {
    font-family: quando;
    font-size: 10px;
}
.educationPageClasses {
    display: flex;
    flex-wrap: wrap;
}

.educationPageClass {
    padding: 6px;
    margin: 4px;
    font-size: 10px;
    font-family: quando;
    background-color: white;
    border-radius: 20px;
    box-shadow: 3px 3px 5px rgba(89, 89, 89, 0.3);
    width: auto;
    display: inline-block;
}

.coursesText {
    margin-left: 10px;
    font-family: quando;
    font-size: 12px;
    text-decoration: underline;
}

.affiliationsText {
    margin-top: 10px;
    margin-left: 10px;
    font-family: quando;
    font-size: 12px;
    text-decoration: underline;
    
}

.educationPageAffiliations {
    margin-top: 5px;
}

.educationPageAffiliation {
    font-family: quando;
    font-size: 12px;
}

